<template>
  <b-modal
    id="modal-medication-interruption"
    centered
    title="Interrupcion de medicamento"
  >
    <b-row>
      <b-col md="12">
        <b-form-group
          label="Causa de interrupción"
          label-for="cause_interruption"
        >
          <b-form-textarea
            id="cause_interruption"
            v-model="formData.cause_interruption"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="outline-secondary"
        @click="close()"
      >
        Cerrar
      </b-button>
      <b-button
        type="button"
        variant="primary"
        :disabled="isBusy || $v.$invalid"
        @click="saveData"
      >
        <b-spinner
          v-if="isBusy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Guardar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BButton,
  BCol,
  BRow,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    BModal,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BSpinner,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      formData: {
        cause_interruption: '',
      },
      isBusy: false,
    }
  },
  validations: {
    formData: {
      cause_interruption: {
        required,
      },
    },
  },
  methods: {
    saveData() {},
  },
}
</script>

<style lang="scss"></style>
