<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-form-group
          label="Habitos del paciente"
          label-for="habits"
        >
          <b-form-textarea
            id="habits"
            v-model="formData.habits"
            rows="3"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Problemas relacionados con medicamentos y/o dispositivos medicos"
          label-for="problem"
        >
          <b-form-textarea
            id="problem"
            v-model="formData.problem"
            rows="3"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Acciones asertivas o correctivas"
          label-for="actions"
        >
          <b-form-textarea
            id="actions"
            v-model="formData.actions"
            rows="3"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Observaciones"
          label-for="observations"
        >
          <b-form-textarea
            id="observations"
            v-model="formData.observations"
            rows="3"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        class="text-center"
      >
        <b-button
          type="button"
          variant="success"
          :disabled="isBusy || $v.$invalid"
          @click="saveData"
        >
          <b-spinner
            v-if="isBusy"
            small
          />
          <v-icon
            v-else
            name="save"
          />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormTextarea,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      formData: {
        habits: '',
        problem: '',
        actions: '',
        observations: '',
      },
      isBusy: false,
    }
  },
  validations: {
    formData: {
      habits: {
        required,
      },
      problem: {
        required,
      },
      actions: {
        required,
      },
      observations: {
        required,
      },
    },
  },
  methods: {
    saveData() {},
  },
}
</script>

<style>

</style>
