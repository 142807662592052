<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group
          label="Nombre del paciente"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="patient.name"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Sexo"
          label-for="gender"
        >
          <b-form-input
            id="gender"
            v-model="patient.gender"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Edad"
          label-for="age"
        >
          <b-form-input
            id="age"
            v-model="patient.age"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Identificación"
          label-for="identification"
        >
          <b-form-input
            id="identification"
            v-model="patient.identification"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Cama/habitación"
          label-for="bed"
        >
          <b-form-input
            id="bed"
            v-model="patient.bed"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Alergías"
          label-for="allergies"
        >
          <b-form-input
            id="allergies"
            v-model="patient.allergies"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Diagnostico actual"
          label-for="diagnostic"
        >
          <b-form-input
            id="diagnostic"
            v-model="patient.diagnostic"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Patologias asociadas"
          label-for="pathology"
        >
          <b-form-input
            id="pathology"
            v-model="patient.pathology"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="EPS"
          label-for="eps"
        >
          <b-form-input
            id="eps"
            v-model="patient.eps"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Medico tratante"
          label-for="doctor"
        >
          <b-form-input
            id="doctor"
            v-model="patient.doctor"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Fecha de ingreso"
          label-for="date_entry"
        >
          <b-form-input
            id="date_entry"
            v-model="patient.date_entry"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Fecha de salida"
          label-for="departure_date"
        >
          <b-form-input
            id="departure_date"
            v-model="patient.departure_date"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
	BRow,
	BCol,
	BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      patient: {
        name: 'Lionel Messi',
        gender: 'Masculino',
        age: '32 años',
        identification: '123456789',
        bed: '301-A',
        allergies: 'Alergia',
        diagnostic: 'diagnostico actual',
        pathology: 'Patologia',
        eps: 'Salud total',
        doctor: 'Pancracio Perez',
        date_entry: '1/12/2021',
        departure_date: '1/12/2021',
      },
    }
  },
}
</script>

<style>

</style>
