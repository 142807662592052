<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12">
        <b-card
          no-body
          class="card__"
        >
          <template #header>
            <div class="title">
              <strong>Perfil farmacoterapeutico</strong>
            </div>
          </template>
          <patient-data />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12">
        <b-card
          no-body
          class="card__"
        >
          <template #header>
            <div class="title">
              <strong>Información del tratamiento farmacológico</strong>
            </div>
          </template>
          <treatment-information-table :treatment-information="treatmentInformation" />
        </b-card>
      </b-col>
    </b-row>
    <medication-interruption-modal />
  </div>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PatientData from './components/PatientData'
import TreatmentInformationTable from './components/TreatmentInformationTable'
import MedicationInterruptionModal from './components/MedicationInterruptionModal'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    PatientData,
    TreatmentInformationTable,
    MedicationInterruptionModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      treatmentInformation: [
        {
          medicine: 'Ibuprofeno 500mg',
          route_administration: 'Oral',
          dosage: '500mg',
          frequency_administration: '8 horas',
          start_date: '01/12/2021',
          care_staff: 'Ana Martinez',
          date_last_administration: '02/12/2021',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
  .card__ {
    box-shadow: 0 5px 11px 0 rgba(100, 100, 100, 0.3),
      0 4px 15px 0 rgba(77, 76, 76, 0.15);
  }

  .card-header {
    background-color: #8f8f91;
    padding: 8px;
    margin-bottom: 15px;
  }

  .title {
    color: white;
    font-size: 18px;
    text-transform: uppercase;
  }
</style>
