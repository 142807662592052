<template>
  <div class="p-1">
    <b-tabs
      pills
    >
      <b-tab
        title="Información Tratamiento"
        active
      >
        <treatment-information />
      </b-tab>
      <b-tab title="Observaciones">
        <observation />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import TreatmentInformation from './TreatmentInformation'
import Observation from './Observation'

export default {
	components: {
		BTabs,
		BTab,
    TreatmentInformation,
    Observation,
	},
}
</script>
